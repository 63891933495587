import TravelCompanyInput from '@naturehouse/design-system/components/atoms/travel-company-input/TravelCompanyInput';
import BigQuery from '../../../common/BigQuery';
import SearchTrackingEvents from '../../../enums/searchTrackingEvents';
import Modal from '../modal/Modal';

class TravelPartySearch extends HTMLElement {
    readonly #modal = new Modal({
        title: this.getAttribute('title') || 'Title',
        classList: ['search-form-modal'],
        hidden: true
    });

    readonly #input = this.querySelector('nh-travel-company') as TravelCompanyInput;

    readonly #travelPartyInputs: HTMLInputElement[] = [];

    readonly #showModal = (): void => {
        if (this.#modal.isOpen) {
            return;
        }
        this.#modal.open({
            bindToElement: this.#input
        });
    };

    readonly #onPersonChange = (event: Event): void => {
        const input = event.target as HTMLInputElement;
        this.#input.setAttribute(input.id, input.value);

        this.#trackSearchEvent();
    };

    readonly #inputChange = (): void => {
        const values = this.#input.value === '' ? {} : JSON.parse(this.#input.value);

        ['adults', 'children', 'babies', 'pets'].forEach((part): void => {
            const element = this.#travelPartyInputs.find(
                (input) => input.getAttribute('name') === part
            );

            if (element === undefined) {
                return;
            }

            element.value = values[part] || '0';
        });
    };

    readonly #onCleared = (): void => {
        this.#travelPartyInputs.forEach((input) => {
            input.value = '0';
        });
    };

    constructor() {
        super();

        this.#modal.dataset.for = 'travel-party-search';

        const template = this.querySelector('#travel-party-content') as HTMLTemplateElement;
        const footerTemplate = this.querySelector(
            '#travel-party-modal-footer'
        ) as HTMLTemplateElement;
        if (!template || !footerTemplate) {
            throw new Error('Template not found');
        }

        const content = document.importNode(template.content, true);
        const footer = document.importNode(footerTemplate.content, true);
        this.#travelPartyInputs = Array.from(content.querySelectorAll('input'));

        this.#modal.classList.add('nh-modal--minimal');
        this.#modal.content = content;
        this.#modal.footer = footer;
        document.body.append(this.#modal);

        this.#setDoneButtonEvents();
        this.#setClearButtonEvents();
    }

    protected connectedCallback(): void {
        this.#input.addEventListener('click', this.#showModal.bind(this));
        this.#input.addEventListener('cleared', this.#onCleared);

        this.#input.addEventListener('change', this.#inputChange);

        this.#travelPartyInputs.forEach((input) => {
            input.addEventListener('change', this.#onPersonChange);
        });
    }

    protected disconnectedCallback(): void {
        this.#input.removeEventListener('click', this.#showModal);
        this.#input.removeEventListener('cleared', this.#onCleared);

        this.#travelPartyInputs.forEach((input) => {
            input.removeEventListener('change', this.#onPersonChange);
        });
    }

    #trackSearchEvent(): void {
        const data: GoogleAnalytics4TravelPartySearchEvent = {
            adults: this.#getInputValue('adults'),
            children: this.#getInputValue('children'),
            babies: this.#getInputValue('babies'),
            pets: this.#getInputValue('pets'),
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.trackWithDelay({
            eventName: SearchTrackingEvents.TRAVEL_PARTY,
            eventParams: [
                { eventKey: 'adults', eventValue: data.adults.toString() },
                { eventKey: 'children', eventValue: data.children.toString() },
                { eventKey: 'babies', eventValue: data.babies.toString() },
                { eventKey: 'pets', eventValue: data.pets.toString() },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }

    #getInputValue(name: string): number {
        const input = this.#travelPartyInputs.find((i) => i.name === name) ?? null;
        if (input === null) {
            return 0;
        }

        return Number(input.value);
    }

    #setDoneButtonEvents(): void {
        const doneButton = this.#modal.footer.querySelector(
            '[data-role="close-modal"]'
        ) as HTMLButtonElement | null;

        if (doneButton) {
            doneButton.addEventListener('click', () => {
                if (this.#modal.isOpen) {
                    this.#modal.close();
                }
            });
        }
    }

    #setClearButtonEvents(): void {
        const clearButton = this.#modal.footer.querySelector(
            '[data-role="clear"]'
        ) as HTMLButtonElement | null;

        if (clearButton) {
            clearButton.addEventListener('click', (): void => {
                this.#input.dispatchEvent(new Event('clear'));
            });
        }
    }
}

if (!customElements.get('travel-party-search')) {
    customElements.define('travel-party-search', TravelPartySearch);
}
